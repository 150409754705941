import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import drag from "../../../assets/images/icons/drag.png";
import man2 from "../../../assets/images/icons/profile.png";
import edit from "../../../assets/images/icons/edit.png";
import open_eye from "../../../assets/images/icons/open-eye.png";
import clock from "../../../assets/images/icons/clock.png";
import { filter_icon } from '../../../assets/images';
import { SEARCH_QUOTATION_BLANK } from '../../../store/Sales/actiontype';
import { QUOTATION_RESULT_SELECTED_BLANK } from '../../../store/InstantRate/actionType';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TransferDialog from '../Quotations/Dialog/TransferDialog';
import { postTransferStatusData } from '../../../store/Sales/actions';
import { useDispatch } from 'react-redux';
import { customerApprvd, customerReject, draft } from '../../Dashboard/Partials/initialData';


const QueriesCompDemo = ({ QuoteModalHandler, previewModalHand, toggleRightCanvas }) => {
  const isAllowedDrage = [draft];
  const { fcl_summary_data } = useSelector((state) => state?.quotation);
  const { is_transfer_status_loader ,inquiry_transfer_status_data_loader } = useSelector((state) => state?.sales);
  const { status_master } = useSelector((state) => state.globalReducer);

  const dispatch = useDispatch();
  const [items, setItems] = useState({});
  const [isOpenTransferDialog, setIsOpenTransferDialog] = useState(false);
  const [dragDestination, setDragDestination] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const navigate = useNavigate();

  const cleanupDragData = useCallback(()=>{
    setIsOpenTransferDialog(false);
    setDraggedItem(null);
    setDragDestination(null)
  },[])

  const onDragStart = (start) => {
    const { source } = start;
    const item = items[source.droppableId][source.index];
    if (isAllowedDrage.includes(item.statusCode)) {
      setDraggedItem({ ...item, index: source.index, droppableId: source.droppableId }); // Store the dragged item with index and droppableId
    }
  };

  const onDragEnd = (result) => {
    const { destination } = result;

    if (!destination || !draggedItem) return;

    setDragDestination(destination);
    setIsOpenTransferDialog(true);
  };



  useEffect(() => {
    setItems(fcl_summary_data)
  }, [fcl_summary_data])


  useEffect(() => {
    cleanupDragData();
  }, [inquiry_transfer_status_data_loader])



  const handleTransferConfirm = async () => {
    dispatch(postTransferStatusData({ quotationId: draggedItem?.id, statusId: status_master?.find((item)=>item?.statusCode === dragDestination?.droppableId)?.id }))
  };

  const calculatePercentage = (listType) => {
    const list1Length = items?.[draft]?.length || 0;
    const list2Length = items?.[customerReject]?.length || 0;
    const list3Length = items?.[customerApprvd]?.length || 0;

    const totalLength = list1Length + list2Length + list3Length;

    if (totalLength > 0) {
      if (listType === draft) {
        return Math.round((list1Length / totalLength) * 100);
      } else if (listType === customerReject) {
        return Math.round((list2Length / totalLength) * 100);
      } else if (listType === customerApprvd) {
        return Math.round((list3Length / totalLength) * 100);
      }
    }

    return 0; // Return 0 if total length is 0 or invalid listType
  };

  // Call the function to calculate percentages for both lists
  const percentageForList1 = calculatePercentage(draft);
  const percentageForList2 = calculatePercentage(customerReject);
  const percentageForList3 = calculatePercentage(customerApprvd);
  

  const remainingPercentage1 = 100 - percentageForList1;
  const remainingPercentage2 = 100 - percentageForList2;
  const remainingPercentage3 = 100 - percentageForList3;
  
  return (
    <>
      <div className=" mb-5">
        <div className=" freight_filter_wrap d-flex align-items-center">
          <div className="label flex-grow-1 ">
          </div>
          <div className="right_actions_wrap flex-shrink-0 d-flex align-items-center">
            <div className="search_form">
              <form>
                <div className="position-relative">
                  <input type="search" className="form-control" placeholder="Search" value="" />
                  <button className="btn" type="button"><i className="bx bx-search-alt-2 align-middle"></i></button>
                </div>
              </form>
            </div>
            <div className="filter_wrap">
              <button className="bg-transparent">
                <img src={filter_icon} onClick={toggleRightCanvas} alt="filter" /></button></div><div className="upload_wrap">
            </div>
            <div className="add_btn">
              <button className="border-0" onClick={() => { navigate('/instant-rate/search-rate'); dispatch({ type: SEARCH_QUOTATION_BLANK }); dispatch({ type: QUOTATION_RESULT_SELECTED_BLANK }); }}><i className="bx bx-plus align-middle"></i> Create</button>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mobScreen">
          <div className="card mb-4 innerbox2">
            <div className="d-flex align-items-center circlebox">
              <div className="circle-container">
                <div className="circle percentage-60">
                  {/* <span>{items?.DRAFT?.length > 0 ? Math.round((items?.DRAFT?.length / (items?.DRAFT?.length + items?.CUST_REJECT?.length + items?.CUST_APPRVD?.length)) * 100) : 0} %</span>
                  <div className="percentage-bar"></div> */}
                   <span>{percentageForList1} %</span>
                   <div className="percentage-bar" style={{clipPath: `inset(0 0 0 ${remainingPercentage1}%)`}}></div>
                </div>
              </div>
              <h3 className="mb-0 ms-4">
                In Progress <span className="fw-400">({items?.DRAFT?.length}/{(items?.DRAFT?.length + items?.CUST_REJECT?.length + items?.CUST_APPRVD?.length)})</span>
              </h3>
            </div>
          </div>

          <div className="card mb-4 innerbox2">
            <div className="d-flex align-items-center circlebox">
              <div className="circle1-container">
                <div className="circle1 percentage-40">
                  {/* <span>{items?.CUST_REJECT?.length ? Math.round((items?.CUST_REJECT?.length / (items?.DRAFT?.length + items?.CUST_REJECT?.length + items?.CUST_APPRVD?.length)) * 100) : 0} %</span>
                  <div className="percentage-bar"></div> */}
                  <span>{percentageForList2} %</span>
                   <div className="percentage-bar" style={{clipPath: `inset(0 0 0 ${remainingPercentage2}%)`}}></div>
                </div>
              </div>
              <h3 className="mb-0 ms-4">
                Lost <span className="fw-400">({items?.CUST_REJECT?.length}/{(items?.DRAFT?.length + items?.CUST_REJECT?.length + items?.CUST_APPRVD?.length)})</span>
              </h3>
            </div>
          </div>

          <div className="card mb-4 innerbox2"
          >
            <div className="d-flex align-items-center circlebox">
              <div className="circle2-container">
                <div className="circle2 percentage-60">
                  {/* <span>{items?.CUST_APPRVD?.length ? Math.round((items?.CUST_APPRVD?.length / (items?.DRAFT?.length + items?.CUST_REJECT?.length + items?.CUST_APPRVD?.length)) * 100) : 0} %</span>
                  <div className="percentage-bar"></div> */}
                   <span>{percentageForList3} %</span>
                   <div className="percentage-bar" style={{clipPath: `inset(0 0 0 ${remainingPercentage3}%)`}}></div>
                </div>
              </div>
              <h3 className="mb-0 ms-4">
                Won <span className="fw-400">({items?.CUST_APPRVD?.length}/{(items?.DRAFT?.length + items?.CUST_REJECT?.length + items?.CUST_APPRVD?.length)})</span>
              </h3>
            </div>
          </div>
        </div>
        <DragDropContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}>
          <div className='row'>
            {Object.keys(items).map((listKey) => (
              <Droppable droppableId={listKey} key={listKey}>
                {(provided) => (
                  <div
                    className="mt-0 col-4 d-flex dragdrop_content"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <div className="w-100 colBoxMain">
                      <div className="mainCol w-100">
                        {items[listKey].map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {(provided) => (
                              <div
                                className="colBox"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="card w-100 h-100">
                                  <div className="card-header d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                      <img src={drag} alt="profile" className="profile1" />
                                      <p className="mb-0 rounded px-2 py-1 custom-text text-primary text-nowrap">
                                        {item.sequenceId}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">{item?.inquiryDate}</p>
                                    </div>
                                  </div>
                                  <div className='p-2'>
                                    <div className="d-flex justify-content-between">
                                      <ul className="list-unstyled method1">
                                        <li>Customer</li>
                                        <li>Status</li>
                                        <li>Origin Port</li>
                                        <li>Destination Port</li>
                                        <li>Version</li>
                                        <li>Last Update</li>
                                      </ul>
                                      <ul className="list-unstyled text-end method2">
                                        <li>{item?.customerName}</li>
                                        <li>{item?.statusCode}</li>
                                        <li>{item?.originPortName || "-"}</li>
                                        <li>{item?.destinationPortName || "-"}</li>
                                        <li>{item?.version}</li>
                                        <li>{new Date(item?.lastUpdatedDate).toISOString().split('T')[0]} </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="card-footer p-1 pt-2 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                      <img src={man2} alt="profile" className="profile" />
                                      <p className="mb-0">{item?.salesUserName}</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      {/* <img src={clock} alt="profile" onClick={previewModalHand} className="profile1" /> */}
                                      <img src={clock} alt="profile" onClick={()=>QuoteModalHandler('history', item) } className="profile1" />
                                      <img src={open_eye} onClick={()=>QuoteModalHandler('pdf', item)  } alt="profile" className="profile1" />
                                      {isAllowedDrage.includes(item.statusCode) && <img src={edit} onClick={() => QuoteModalHandler('edit', item)} alt="profile" className="profile1" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
      <TransferDialog
        isOpen={isOpenTransferDialog}
        onClose={cleanupDragData}
        onConfirm={handleTransferConfirm}
        loading={is_transfer_status_loader}
        data={draggedItem?.sequenceId}
        message={dragDestination?.droppableId === customerReject ? "Lost" : dragDestination?.droppableId === customerApprvd ? "Won" : ""}
      />
    </>
  )
}
export default QueriesCompDemo;