
import { get, post } from "../api_helper";
import * as url from "../url_helper"

export const postQuotationSummerySer = (data) => post(url.QUOTATION_SUMMERY, data);
export const getQuotationSummerySer = (data) => get(url.QUOTATION_SUMMERY+data);
export const getQuotationSummeryByIdSer = (data) => get(url.QUOTATION_SUMMERY+data);
export const getQuotationHistoryByIdSer = (data) => get(url.QUOTATION_HISTORY+data);

export const postTransferStatusSer = (quotationId,statusId) =>  post(url.POST_TRANSFER_STATUS + quotationId + "/" + statusId);

export const getDashBoardQuotionSummerySer=() => get(url.DAHSBOARD_QUOTATION_SUMMERY)
