export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS'
export const GET_VENDOR_DETAILS_SUCCESS = 'GET_VENDOR_DETAILS_SUCCESS'

export const GET_CURRENCY_DETAIL = 'GET_CURRENCY_DETAIL'
export const GET_CURRENCY_DETAIL_SUCCESS = 'GET_CURRENCY_DETAIL_SUCCESS'

export const GET_UOM_DATA = 'GET_UOM_DATA'
export const GET_UOM_DATA_SUCCESS = 'GET_UOM_DATA_SUCCESS'
export const GET_UOM_WEIGHT_DATA = 'GET_UOM_WEIGHT_DATA'
export const GET_UOM_WEIGHT_DATA_SUCCESS = 'GET_UOM_WEIGHT_DATA_SUCCESS'

export const GET_SURCHARGE_CODE_DATA = 'GET_SURCHARGE_CODE_DATA'
export const GET_SURCHARGE_CODE_DATA_SUCCESS = 'GET_SURCHARGE_CODE_DATA_SUCCESS'

export const GET_SURCHARGE_CATEGORY_DATA = 'GET_SURCHARGE_CATEGORY_DATA'
export const GET_SURCHARGE_CATEGORY_DATA_SUCCESS = 'GET_SURCHARGE_CATEGORY_DATA_SUCCESS'

export const GET_OCEAEN_PORT_DATA = 'GET_OCEAEN_PORT_DATA'
export const GET_OCEAEN_PORT_DATA_SUCCEESS = 'GET_OCEAEN_PORT_DATA_SUCCEESS'

export const GET_CARGO_TYPE_DATA = 'GET_CARGO_TYPE_DATA'
export const GET_CARGO_TYPE_DATA_SUCCEESS = 'GET_CARGO_TYPE_DATA_SUCCEESS'

export const GET_COMMODITY_DATA = 'GET_COMMODITY_DATA'
export const GET_COMMODITY_DATA_SUCCEESS = 'GET_COMMODITY_DATA_SUCCEESS'

export const GET_CONTAINER_DATA = 'GET_CONTAINER_DATA'
export const GET_CONTAINER_DATA_SUCCEESS = 'GET_CONTAINER_DATA_SUCCEESS'

export const GET_SURCHARGE_ALICE_DATA = 'GET_SURCHARGE_ALICE_DATA'
export const GET_SURCHARGE_ALICE_DATA_SUCCEESS = 'GET_SURCHARGE_ALICE_DATA_SUCCEESS'

// state
export const GET_STATE_ALL_TYPE = 'GET_STATE_ALL_TYPE'
export const GET_STATE_ALL_TYPE_SUCCEESS = 'GET_STATE_ALL_TYPE_SUCCEESS'

// Role
export const GET_ROLE_TYPE = 'GET_ROLE_TYPE'
export const GET_ROLE_TYPE_SUCCEESS = 'GET_ROLE_TYPE_SUCCEESS'
export const GET_ROLE_LOADER_TYPE = 'GET_ROLE_LOADER_TYPE';

export const SAVE_ROLE_TYPE = 'SAVE_ROLE_TYPE';
export const SAVE_ROLE_TYPE_SUCCEESS = 'SAVE_ROLE_TYPE_SUCCEESS'

// post
export const POST_SURCHARGE_CODE_DATA = 'POST_SURCHARGE_CODE_DATA'
export const POST_SURCHARGE_CODE_DATA_SUCCEESS = 'POST_SURCHARGE_CODE_DATA_SUCCEESS'

export const POST_SURCHARGE_CATEGORY_DATA = 'POST_SURCHARGE_CATEGORY_DATA'
export const POST_SURCHARGE_ALISE_DATA = 'POST_SURCHARGE_ALISE_DATA'

// module
export const GET_MODULE_TYPE = 'GET_MODULE_TYPE'
export const GET_MODULE_TYPE_SUCCEESS = 'GET_MODULE_TYPE_SUCCEESS'
export const GET_MODULE_LOADER_TYPE = 'GET_MODULE_LOADER_TYPE';

export const GET_ALL_MODULES_BY_ROLE_TYPE = "GET_ALL_MODULES_BY_ROLE_TYPE"
export const GET_ALL_MODULES_BY_ROLE_TYPE_SUCCEESS = "GET_ALL_MODULES_BY_ROLE_TYPE_SUCCEESS"

export const DELETE_PERMISSIONS_TYPE = 'DELETE_PERMISSIONS_TYPE';
export const DELETE_PERMISSIONS_TYPE_SUCCEESS = 'DELETE_PERMISSIONS_TYPE_SUCCEESS';

export const SAVE_PERMISSIONS_TYPE = 'SAVE_PERMISSIONS_TYPE';
export const SAVE_PERMISSIONS_TYPE_SUCCEESS = 'SAVE_PERMISSIONS_TYPE_SUCCEESS';

export const GET_ROLE_BY_ID_TYPE = 'GET_ROLE_BY_ID_TYPE';
export const GET_ROLE_BY_ID_TYPE_SUCCEESS = 'GET_ROLE_BY_ID_TYPE_SUCCEESS'

export const GET_ALL_FILES_DATA="GET_ALL_FILES_DATA"
export const GET_ALL_FILES_DATA_LOADER="GET_ALL_FILES_DATA_LOADER"
export const GET_ALL_FILES_DATA_SUCCESS="GET_ALL_FILES_DATA_SUCCESS"

export const SET_NEW_PERMISSION="SET_NEW_PERMISSION"


//OceanPortTerminal
export const GET_OCEAN_PORT_TERMINAL_DETAILS = 'GET_OCEAN_PORT_TERMINAL_DETAILS'
export const GET_OCEAN_PORT_TERMINAL_DETAILS_SUCCESS = 'GET_OCEAN_PORT_TERMINAL_DETAILS_SUCCESS'

export const GET_STATUS_MASTER_DETAILS = 'GET_STATUS_MASTER_DETAILS'
export const GET_STATUS_MASTER_DETAILS_SUCCESS = 'GET_STATUS_MASTER_DETAILS_SUCCESS'

export const GET_REASON_MASTER_DETAILS = 'GET_REASON_MASTER_DETAILS'
export const GET_REASON_MASTER_DETAILS_SUCCESS = 'GET_REASON_MASTER_DETAILS_SUCCESS'
